import { useSnackbar as useDefaultSnackbar } from "notistack";
import SnackMessage from "./popOver";

export const useEnqueuSnackbar = () => {
  const { enqueueSnackbar } = useDefaultSnackbar();

  const pushSnackbar = (message, options) => {
    enqueueSnackbar(message, {
      ...options,
      content: (key) => {
        // destructure the options we need from the extended options
        // object, and provide a default case if we didn't provide any
        const { variant } = options || { variant: undefined };
        return (
          <SnackMessage
            id={`${key}`}
            message={message}
            variant={variant || "success"}
          />
        );
      },
    });
  };

  return pushSnackbar;
};