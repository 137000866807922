import { createSlice } from '@reduxjs/toolkit';

// import _ from 'lodash';

import axios from 'axios';

const initialState = {
  isLoaded: false,
  products: []
};

const slice = createSlice({
  name: 'user_deals',
  initialState,
  reducers: {
    setProducts(state, action){
        
      const { deals } = action.payload;
      state.products = [...deals];
      state.isLoaded = true;
    },
    delProduct(state, action){ 
      const { deal } = action.payload;
      state.products = state.products.filter(item => item.SK !== deal.SK);
      state.isLoaded = true;
    },
  }
});

export const reducer = slice.reducer;
export const delProduct = (token, deal) => async (dispatch) => {
    let config = {
        method: 'delete',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/deals`,
        headers: { 
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(deal)
      };
    //   console.log("***********---------------------********");
    //   console.log(config)
  
      
      let response = await axios(config).catch(() => {
        console.error(`Can't login, error reaching backend.`);
        return {
          error: true
        }
  
      });
      if(!response.error){  
        dispatch(slice.actions.delProduct({deal}))
        // if (isMountedRef.current) {
        //   console.log(response)
        //   let arr = products.filter(item => item.SK !== deal.SK)
        //   setProducts(arr)
        // } 
      }
    
  };

export const getProducts = (token) => async (dispatch) => {
    let config = {
      method: 'get',
      url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/deals`,
      headers: { 
        'Accept': '*/*',
        'Authorization': `Bearer ${token}`
      }
    };

    
    let response = await axios(config).catch(() => {
      console.error(`Can't login, error reaching backend.`);
      return {
        error: true
      }

    });
    console.log("***********---------------------********");
    console.log(response)
    
    dispatch(slice.actions.setProducts(response.data.data));
  };
  


export default slice;
