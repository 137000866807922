import { createSlice } from '@reduxjs/toolkit';

// import _ from 'lodash';

import axios from 'axios';

const refillTimes = [
    { duration: 'Monthly', amnt: 1, type: 'month', dateType: 'months', dateAmnt: 1 },// month 
    { duration: 'Quarterly', amnt: 1, type: 'quarter', dateType: 'days', dateAmnt: Math.floor(365/4) },// quarter
    { duration: 'Bi-Annually', amnt: 2, type: 'quarter', dateType: 'days', dateAmnt: Math.floor(365/2)},// quarter (2 units)
    { duration: 'Yearly', amnt: 1, type: 'year', dateType: 'years', dateAmnt: 10 }// year
  ];
   
const dealTypes = [
    {string: 'Normal', value: 'default'},
    {string: 'Cost +', value: 'cost'},
]

const initialState = {
    MAX_DEALS: 20,
  isLoaded: false,
  refilling: false,
  refillDuration: refillTimes[0],
  lastRefill: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  maxQuant: 0,
  curQuant: 0,
  discount: 25,
  tags: [],
  type: dealTypes[0].value,
  specifications: "",
  isSubmitting: false,
  dealTypes: [
        {string: 'Normal', value: 'default'},
        {string: 'Cost +', value: 'cost'},
    ],
    refillTimes: [
        { duration: 'Monthly', amnt: 1, type: 'month', dateType: 'months', dateAmnt: 1 },// month 
        { duration: 'Quarterly', amnt: 1, type: 'quarter', dateType: 'days', dateAmnt: Math.floor(365/4) },// quarter
        { duration: 'Bi-Annually', amnt: 2, type: 'quarter', dateType: 'days', dateAmnt: Math.floor(365/2)},// quarter (2 units)
        { duration: 'Yearly', amnt: 1, type: 'year', dateType: 'years', dateAmnt: 10 }// year
    ]
};

const slice = createSlice({
  name: 'deal_create',
  initialState,
  reducers: {
    setSubmitting(state, action){
        state.isSubmitting = action.payload;
    },
    setSpecifications(state, action){
        state.specifications = action.payload;
    },
    setTags(state, action){
        state.tags = action.payload;
    },
    setType(state, action){
        state.type = action.payload;
    },
    setLastRefill(state, action){
        state.lastRefill = action.payload;
    },
    handleRefillDuration(state, action){
        state.refillDuration = action.payload;
        if(state.refillDuration.dateType === "minutes"){
            state.lastRefill = new Date();
        }
    },
    handleMaxQuant(state, action){
        if(action.payload.length === 0){
            state.maxQuant = null;
        }else {
            let int = parseInt(action.payload); 

            if(int >= 0 && int <= state.MAX_DEALS){ 
              state.maxQuant = int;
              if(int < state.curQuant)
                state.curQuant = int; 
            }
        }
    },
    handleCurQuant(state, action){
        if(action.payload.length === 0){
            state.curQuant = null;
        }else {
            let int = parseInt(action.payload);  
            if(int >= 0 && int <= state.MAX_DEALS){  
                if(!state.refilling || int >= 0){
                    state.curQuant = int; 
                }
            }
        }
    },
    handleRefillChange(state){
        state.refilling = !state.refilling;
        if(state.refilling){
            state.refillDuration = refillTimes[0]
            state.maxQuant = 0;
        }else {
            state.maxQuant = 1;
            state.curQuant = 1;
        }
    },
    setDiscount(state, action) {
        state.discount = action.payload
    }
  }
});

export const reducer = slice.reducer;

export const setSpecifications = (val) => async (dispatch) => { 
    dispatch(slice.actions.setSpecifications(val))
}
export const setTags = (val) => async (dispatch) => { 
    dispatch(slice.actions.setTags(val))
}
export const setDiscount = (val) => async (dispatch) => { 
    dispatch(slice.actions.setDiscount(val))
}
export const handleCurQuant = (val) => async (dispatch) => { 
    dispatch(slice.actions.handleCurQuant(val))
}
export const handleMaxQuant = (val) => async (dispatch) => { 
    dispatch(slice.actions.handleMaxQuant(val))
}
export const setLastRefill = (val) => async (dispatch) => { 
    dispatch(slice.actions.setLastRefill(val))
}
export const handleRefillDuration = (val) => async (dispatch) => { 
    dispatch(slice.actions.handleRefillDuration(val))
}
export const setType = (val) => async (dispatch) => { 
    dispatch(slice.actions.setType(val))
}
export const handleRefillChange = ( ) => async (dispatch) => { 
    dispatch(slice.actions.handleRefillChange())
}

export const submitDeals = (token, state, navigate) => async (dispatch) => { 
    dispatch(slice.actions.setSubmitting(true))

    let data = {
        "reoccuring": state.refilling,
        "maxQuantity": null,
        "curQuantity": state.curQuant,
        "restrictions": state.specifications,
        "specifiers": [...state.tags],
        "baseDiscount": state.discount,
        "type": state.type,
        "schedule": null
      }
  
      if(state.refilling){
        data.schedule = {
          "type": state.refillDuration.type,
          "amnt": state.refillDuration.amnt,
          "lastRefill": new Date(state.lastRefill).getTime()
        }
        data.maxQuantity = state.maxQuant;
      } 
  
      try {
        let response = null;
                        
        let config = {
          method: 'post',
          url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/deals`,
          headers: { 
            'Accept': '*/*',
            'Authorization': `Bearer ${token}`
          },
          data:JSON.stringify(data)
        };
        console.log("***********----------********");
        console.log(config)
  
        
        response = await axios(config).catch(() => {
          console.error(`Can't Create Deal, error reaching backend.`);
          return {
            error: true
          }
  
        });
        if(!response.error){  
          navigate('/site/dashboards/commerce/deals/list', { replace: true });
        }
      } catch (err) {
        console.error(err);
      }
    dispatch(slice.actions.setSubmitting(false))
}
 

export default slice;
