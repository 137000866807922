/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-template */
/* eslint-disable no-unreachable */
import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  alpha,
  tooltipClasses,
  styled,
  useTheme,
  Grid,
  Dialog,
  DialogTitle,
  ListItem,
  Avatar,
  ListItemText,
  Typography,
  Alert,
  TextField,
  CircularProgress,
  Autocomplete,
  Button,
  Divider,
  List,
  Stack,
  Card,
  useMediaQuery
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useTranslation } from 'react-i18next';
// import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import RemoveIcon from '@mui/icons-material/PersonRemove';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import useAuth from 'src/hooks/useAuth';
import {useState, useEffect} from 'react';
import { useDropzone } from 'react-dropzone';  
// eslint-disable-next-line no-unused-vars
import axios  from 'axios'; 
import useRefMounted from 'src/hooks/useRefMounted';  
import { v4 as uuidv4 } from 'uuid'; 

 
const SearchIconWrapper = styled(SearchTwoToneIcon)(
  ({ theme }) => `
        color: ${theme.colors.alpha.black[30]}
`
);


const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
    border: 1px dashed ${theme.colors.alpha.trueWhite[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.trueWhite[5]};
      border-color: ${theme.colors.alpha.trueWhite[70]};
    }
`
);

const UploadBox = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `
);

const DividerContrast = styled(Divider)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
  `
);

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
); 





 

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));



let lastCase = 0;

function SidebarFooter() {
  const { t } = useTranslation();
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const { user, logout, updateUserData, reFetchUserData } = useAuth();
  const navigate = useNavigate(); 
 
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogState, setDialogState] = useState({
    isWorker: false,
    hasStore: false,
    attachWrker: true,
    rmWrker: false, 
    rmStore: false
  });
  


  // eslint-disable-next-line no-unused-vars
  function setDialog(state){

    switch(state){

      case 0: 
      setDialogState({
        isWorker: false,
        hasStore: false,
        attachWrker: true,
        rmWrker: false, 
        rmStore: false
      });
      break;

      case 1: 
      setDialogState({
        ...dialogState,
        attachWrker: false,
        rmWrker: true, 
        rmStore: false
      });
      break;
      
      case 2: 
      setDialogState({
        ...dialogState,
        attachWrker: false,
        rmWrker: false, 
        rmStore: true
      });
      break;

      default: 
      setDialogState({
        isWorker: false,
        hasStore: false,
        attachWrker: false,
        rmWrker: false, 
        rmStore: false
      });
      break;
    }
  }
  
  // eslint-disable-next-line no-unused-vars
  const [filesToUpload, setFilesToUpload] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const isMountedRef = useRefMounted();
  // eslint-disable-next-line no-unused-vars
  const [wrkerDialog, setWrkerDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [storeDialog, setStoreDialog] = useState(false); 
  // eslint-disable-next-line no-unused-vars
  const [wrkerStores, setWrkerStores] = useState([]);

  const [operatingLocations, setOperatingLocations] = useState([]);

  const [retrieving, setRetrieving] = useState(false);


  
  useEffect(async () => {
    console.log("***************USER DATA*****************")
    console.log(user);
    console.log("***************END DATA*****************") 
    setRetrieving(true); 
    await getLocations(); 
    setRetrieving(false);
  }, []);
   

  const getLocations = async () => {
    try {
      let response = null;
                        
      let config = {
        method: 'get',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/global/locations`,
        headers: { 
          'Accept': '*/*',
        }
      };
      // console.log("***********---------------------********");
      // console.log(config)

      
      response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
          error: true
        }

      });
      if(!response.error){ 
        setOperatingLocations(response.data.data.locations);
      }
      // setDeals([]);
    } catch (err) {
      console.error(err);
    }
  }

  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({ 
    accept: 'image/jpeg',
    multiple: true,
    maxFiles: 3,
    maxSize: 5000000,
    onDrop: async files => {
      
      // let images = [...filesToUpload]; // to add on rather than replace
      let images = [];
      let totalSize = 0;
      for(let x = 0; x < files.length; x++){ 
        // console.log(files[x].name.split('.').pop().toLowerCase())
        // eslint-disable-next-line no-await-in-loop
        let result = await new Promise((resolve) => {
          let fileReader = new FileReader();
          // eslint-disable-next-line no-unused-vars
          fileReader.onload = (e) => resolve(fileReader.result);
          fileReader.readAsDataURL(files[x]);
        });
        totalSize += (new Blob([result])).size;
        if(totalSize < 10000000) // 10MB
          images.push({name: `${uuidv4()}.${files[x].name.split('.').pop().toLowerCase()}`, data: result}); 
      }
      setFilesToUpload([...images]);
      
      // console.log(images)
      // console.log("Cumulative Size", totalSize)
    }
  });


  const files = acceptedFiles.map((file, index) => (
    <ListItem disableGutters component="div" key={index}>
      <ListItemText primary={file.name} />
      <b>{file.size} bytes</b>
      <DividerContrast />
    </ListItem>
  ));

 
  if(user.worker && user.worker.id){ 
    if(user.worker.store && lastCase !== 2){ 
      setDialog(2);
      lastCase = 2;
    }else if(!user.worker.store &&lastCase !== 1){
      setDialog(1);
      lastCase = 1;
    }
  }else if(!user.worker && lastCase !== 0){
    setDialog(0);
    lastCase = 0;
  } 
  

  // eslint-disable-next-line no-unused-vars
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };


  

  async function uploadProof(){ 
    
    let config = {
      method: 'post',
      url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/app/images`,
      headers: { 
        'Accept': '*/*',
        'Authorization': `Bearer ${user.sessionToken}`
      },
      data: { 
        imgList: [...filesToUpload],
      }
    };
    let response = await axios(config).catch((error) => {
      console.error(`Can't Fetch stores, error reaching backend.`, error);
      return {
        error: true
      }

    });
    console.log(response);
    if(!response.error){  
       
      // await getChatMsgs(); 
      // setChats({userChats: [...response.data.data.userChats], workerChats: [...response.data.data.workerChats]});
    }
  }


  return (
    <Box
      sx={{
        height: 60
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
    {(user.worker === null ||  !user.worker.id || (user.worker && user.worker.id && !user.worker.store)) && !user.metaData.workerStatus &&   
      <LightTooltip placement="top" arrow title={t('Attach Worker')}>
    
        <Badge
        color="success"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '.MuiBadge-badge': {
            animation: 'pulse 1s infinite',
            top: '5%',
            transition: `${theme.transitions.create(['all'])}`
          }
        }}
        variant="dot"
        overlap="circular"
        > 
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }} 
          onClick={() => {
            setWrkerDialog(true);
          }} 
        >
          <EngineeringIcon fontSize="small" />
        </IconButton>
        </Badge>
      </LightTooltip>

  }
      
      {user.worker !== null && user.worker.id && user.worker.store &&
        <LightTooltip placement="top" arrow title={t('Remove Store (Disabled until Order transitions)')}>
          <Badge
          color="success"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              animation: 'pulse 1s infinite',
              top: '5%',
              transition: `${theme.transitions.create(['all'])}`
            }
          }}
          variant="dot"
          overlap="circular"
          >
            <IconButton
            disabled
              sx={{
                background: `${theme.colors.alpha.trueWhite[10]}`,
                color: `${theme.colors.alpha.trueWhite[70]}`,
                transition: `${theme.transitions.create(['all'])}`,

                '&:hover': {
                  background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                  color: `${theme.colors.alpha.trueWhite[100]}`
                }
              }} 
              onClick={async () => {
                try {
    
                  
                  let config = {
                    method: 'delete',
                    url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/store`,
                    headers: { 
                      'Accept': '*/*',
                      'Authorization': `Bearer ${user.sessionToken}`
                    },
                    data: JSON.stringify({id: user.worker.id}) 
                  };
                  console.log("***********---------------------********");
                  console.log(config)
                  
                  let resp = await axios(config).catch((err) => {
                    console.error(`error reaching backend.`);
                    console.log(err);
                    return {
                      error: true
                    }
    
                  });
                  

                  console.log(resp) 
                  if(!resp.error){
                    let wrker = {
                      id: user.worker.id,
                      store: null
                    }; 
                    updateUserData({...user, worker: {...wrker}});
                  }
                  
                } catch (err) {
                  console.error(JSON.stringify(err, null, 4)); 
                }





              }} 
            >
              <RemoveIcon fontSize="small" />
            </IconButton>
          </Badge>
        </LightTooltip>
    }
      {/* <LightTooltip placement="top" arrow title={t('Events Calendars')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          to="/site/applications/calendar"
          component={RouterLink}
        >
          <EventTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip> */}
      <LightTooltip placement="top" arrow title={t('Messenger')}>
          <IconButton
            to="/site/applications/messenger"
            component={RouterLink}
            sx={{
              background: `${theme.colors.alpha.trueWhite[10]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              },
              mx: 1
            }}
          >
            <SmsTwoToneIcon fontSize="small" />
          </IconButton> 
      </LightTooltip>
      <LightTooltip placement="top" arrow title={t('Logout')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          onClick={handleLogout}
        >
          <PowerSettingsNewTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>



      <Dialog
        fullWidth
        maxWidth="md"
        open={wrkerDialog}
        // onClose={handleCreateInvoiceClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Register under a store')}
          </Typography> 
        </DialogTitle>
            <Formik
          initialValues={{
            location: {},
            store: {}
          }}
          validationSchema={Yup.object().shape({
            location: Yup.object()
              .required(t('The location field is required')), 
          })}
          // eslint-disable-next-line no-unused-vars
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            setSubmitting(true);
            try {
              await uploadProof();

              console.log(values);
              let config = {
                method: 'post',
                url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker`,
                headers: { 
                  'Accept': '*/*',
                  'Authorization': `Bearer ${user.sessionToken}`
                },
                data: {
                  storehash: values.store.hashKey,
                  storerange: values.store.rangeKey
                }
              };
              console.log("***********---------------------********");
              console.log(config)
              
              let resp = await axios(config).catch((err) => {
                console.error(`Can't login, error reaching backend.`);
                console.log(err);
                return {
                  error: true
                }

              });
              await reFetchUserData();
              console.log(resp)


              // let preId = null;
              // let force = false;
              // if(resp.data.code === 200 && resp.data.data.oldItem){
              //   force = true;
              //   preId = resp.data.data.data;
              // }

              // if(force || !resp.error && resp.data.code === 200 && resp.data && resp.data.data){
              //   let wrker = {
              //     id: preId || resp.data.data.id,
              //     store: null
              //   };
              //   config = {
              //     method: 'put',
              //     url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker`,
              //     params: {
              //       wkid: wrker.id,
              //       storehash: values.store.hashKey,
              //       storerange: values.store.rangeKey
              //     },
              //     headers: { 
              //       'Accept': '*/*',
              //       'Authorization': `Bearer ${user.sessionToken}`
              //     }
              //   };
              //   console.log("***********---------------------********");
              //   console.log(config) 
                
              //   let response = await axios(config).catch((err) => {
              //     console.error(`Can't login, error reaching backend.`);
              //     console.log(err);
              //     return {
              //       error: true
              //     }
  
              //   }); 
              //   console.log(response)
                
              //   if(!response.error && response.data.code === 200 && response.data && response.data.data){
              //     wrker.store = {...response.data.data};
              //   }
              //   updateUserData({...user, worker: {...wrker}});
              // }
              lastCase = 0;
              setWrkerDialog(false);
              setStoreDialog(0); 
              setSubmitting(false);
              // await handleLogout();
            } catch (err) {
              console.error(JSON.stringify(err, null, 4));
              setWrkerDialog(false);
              setStoreDialog(0);  
              // // UserNotConfirmedException
              

              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            // handleChange,
            handleSubmit,
            isSubmitting, 
            values,
            setFieldValue,
            // submitForm,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
        
              <Grid item xs={12}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Box display={{ xs: 'none', lg: 'flex' }} ml={2} flexShrink={1}>
                    <SearchIconWrapper />
                  </Box>
                  <Stack
                    sx={{
                      p: 2,
                      flex: 1
                    }}
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={2}
                    divider={<Divider orientation="vertical" flexItem />}
                  > 
                    <Autocomplete
                      fullWidth
                      limitTags={1} 
                      options={operatingLocations}
                      defaultValue={null}
                      disabled={retrieving}
                      getOptionLabel={(option) => option.City + ", " + option.Country}
                      onBlur={handleBlur}
                      onChange={async (evt, val) => { 
                        setFieldValue("location", val) 
                        setRetrieving(true);
                        console.log("********************************");
                        console.log(user)
                        console.log("***---------------------**************");
                        console.log(val)
                        if(val === null)
                          return
                        
                        let config = {
                          method: 'get',
                          url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/store/location?filter=geo&latitude=${val.coords.lat}&longitude=${val.coords.lon}`,
                          headers: { 
                            'Accept': '*/*',
                            'Authorization': `Bearer ${user.sessionToken}`
                          }
                        };
                        console.log("***********---------------------********");
                        console.log(config)
 
                        
                        let resp = await axios(config).catch(() => {
                          console.error(`Can't login, error reaching backend.`);
                          return {
                            error: true
                          }

                        });
                        
                        setRetrieving(false);
                        if(!resp.error && resp.data.code === 200 && resp.data && resp.data.data){
                          setWrkerStores([...resp.data.data]);

                          console.log([...resp.data.data]);
                        }
                      }}
                      name="location"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label={t('Location')}
                          placeholder={t('Select location...')}
                        />
                      )}
                    />
                    {retrieving && <CircularProgress size="1rem" />}


                    {wrkerStores.length > 0 && <Autocomplete                    
                      fullWidth
                      limitTags={1}
                      options={wrkerStores}
                      getOptionLabel={(option) => option.brand.toUpperCase() + " : " + option.address}
                      defaultValue={null}
                      onBlur={handleBlur}
                      onChange={async (evt, val) => { 
                        setFieldValue("store", {...val}) 
                        console.log(values) 
                        console.log(val) 
                        if(val === null)
                          return
                        console.log(val) 
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant="outlined"
                          label={t('Store')}
                          placeholder={t('Select from Registered Stores...')}
                        />
                      )}
                    />}
                  </Stack>
                </Card>
              </Grid> 
              {Object.keys(values.store).length > 0 && <Grid > 
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {/* <Box 
                    sx={{
                      display: { xs: 'block', sm: 'flex' },
                      alignItems: 'center',
                    }}> */}


                    <UploadBox>
                      <TypographyPrimary variant="h4" gutterBottom>
                        {t('File Upload')}
                      </TypographyPrimary>
                      <TypographySecondary variant="body1">
                        {t('Upload paystubs or any other identifying employment documents')}
                      </TypographySecondary>
            
                      <BoxUploadWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragAccept && (
                          <>
                            <AvatarSuccess variant="rounded">
                              <CheckTwoToneIcon />
                            </AvatarSuccess>
                            <TypographyPrimary
                              sx={{
                                mt: 2
                              }}
                            >
                              {t('Drop the files to start uploading')}
                            </TypographyPrimary>
                          </>
                        )}
                        {isDragReject && (
                          <>
                            <AvatarDanger variant="rounded">
                              <CloseTwoToneIcon />
                            </AvatarDanger>
                            <TypographyPrimary
                              sx={{
                                mt: 2
                              }}
                            >
                              {t('You cannot upload these file types')}
                            </TypographyPrimary>
                          </>
                        )}
                        {!isDragActive && (
                          <>
                            <AvatarWrapper variant="rounded">
                              <CloudUploadTwoToneIcon />
                            </AvatarWrapper>
                            <TypographyPrimary
                              sx={{
                                mt: 2
                              }}
                            >
                              {t('Drag & drop files here')}
                            </TypographyPrimary>
                          </>
                        )}
                      </BoxUploadWrapper>
                      {files.length > 0 && (
                        <>
                          <Alert
                            sx={{
                              py: 0,
                              mt: 2
                            }}
                            severity="success"
                          >
                            {t('You have uploaded')} <b>{files.length}</b> {t('files')}!
                          </Alert>
                          <DividerContrast
                            sx={{
                              mt: 2
                            }}
                          />
                          <List disablePadding component="div">
                            {files}
                          </List>
                        </>
                      )}
                    </UploadBox>
                  {/* </Box> */}
                </Card>
              </Grid> }
              
              <Box
                sx={{
                  display: { xs: 'block', sm: 'flex' },
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 3
                }}
              >
                <Box>
                  <Button 
                    fullWidth={mobile} 
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    } 
                    onClick={async() => {
                      console.log(JSON.stringify(values, null, 4));
                      setWrkerDialog(false);
                      // let resp = await resendCode(values.email);
                      // console.log(JSON.stringify(resp, null, 4));
                    }}
                    variant="contained"
                    size="large"
                  >
                    {t('Close')}
                  </Button>
                </Box> 
                <Box> 
                </Box>
                <Box>
                  <Button
                    fullWidth={mobile} 
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    
                    disabled={Boolean(errors.submit) || isSubmitting || Object.keys(values.store).length === 0 || filesToUpload.length === 0}
                    variant="contained"
                    size="large"
                    type="submit"
                  >
                    {t('Submit')}
                  </Button>
                </Box>
              </Box>


            </form>
          )}
        </Formik>
      </Dialog>

    </Box>
  );
}

export default SidebarFooter;

/*

            
      <Dialog
        // fullWidth
        maxWidth="md"
        open={emailVerification}
        // onClose={handleCreateInvoiceClose}
      >
        <DialogTitle
          sx={{
            p: 3
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t('Please input the MFA code sent to your inbox')}
          </Typography> 
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            p: 3
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box pb={1}>
                <b>{t('MFA code')}:</b>
              </Box>
              <TextField
                error={Boolean(touched.number && errors.number)}
                helperText={touched.number && errors.number}
                name="code"
                placeholder={t('Input MFA Code')}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="outlined"
              />


            </Grid>  
          </Grid>
        </DialogContent>
        
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 3
          }}
        >
          <Box>
            <Button 
              fullWidth={mobile} 
              startIcon={
                isSubmitting ? <CircularProgress size="1rem" /> : null
              } 
              onClick={async() => {
                console.log(JSON.stringify(values, null, 4));
                let resp = await resendCode(values.email);
                console.log(JSON.stringify(resp, null, 4));
              }}
              variant="contained"
              size="large"
            >
              {t('Resend Code')}
            </Button>
          </Box>
          <Box>
            <Button
              fullWidth={mobile} 
              startIcon={
                isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              onClick={async () => {
                await submitForm();
              }}
              disabled={Boolean(errors.submit) || isSubmitting || values.code.length === 0}
              variant="contained"
              size="large"
            >
              {t('Submit')}
            </Button>
          </Box>
        </Box>
      </Dialog>


*/