import { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const usePageTracking = () => {
    const allowLocal = true;
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);
  
    useEffect(() => {
      if (!window.location.href.includes("localhost") || allowLocal) {
        ReactGA.initialize({
            trackingId: 'UA-226875447-2',// 'G-RM4798FMY7', // 'UA-226875447-2',
            debug: true,
            gaOptions: {
            cookieDomain: 'none'
            }
        });
      }
      setInitialized(true);
    }, []);
  useEffect(() => {
    if (initialized) {
        ReactGA.pageview(location.pathname + location.search);
    }
    console.log(location.pathname + location.search)
  }, [initialized, location]);
};

export default usePageTracking;