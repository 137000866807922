import { Fragment, useRef, useState } from 'react';

import {
  IconButton,
  Box, 
  Typography,
  Button,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment, 
  alpha,
  Popover,
  Tooltip,
  TextField,
  styled,
  useTheme,
} from '@mui/material'; 
import useAuth from 'src/hooks/useAuth';
import Text from 'src/components/Text';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send'; 
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSnackbar } from 'notistack';
import axios from 'axios';
// import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone'; 

import { useTranslation } from 'react-i18next';
 

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`
);


const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function Chat() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState({
    subject: null,
    body: null
  });
  const handleChange = (prop) => (event) => {
    setEmail({ ...email, [prop]: event.target.value });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
 

  const submitReport = async () => {
    setSubmitting(true)
    let emailData = {...email, url: window.location.href};
    if(user){
      emailData.user = {...user}
      delete emailData.user.sessionToken;
    }
    console.log(emailData);
    setEmail({
      subject: null,
      body: null
    });

    
    let config = {
      method: 'post',
      url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/user/general`,
      headers: { 
        'Accept': '*/*',
        'Authorization': `Bearer ${user.sessionToken}`
      },
      data: {
        ...emailData
      }
    };
    console.log("***********---------------------********");
    console.log(config)
    
    let resp = await axios(config).catch((err) => {
      console.error(`Can't login, error reaching backend.`);
      console.log(err);
      return {
        error: true
      }

    }); 
 
    if(resp.status === 200 ){  
      
      enqueueSnackbar({
        title: t('Thanks for the feedback <3!'),
        messages: [t('Successfully Submitted Report'), ]}, {
        autoHideDuration: 3000,
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },  
      });  
    } else {

      
      enqueueSnackbar({
        title: t('Thanks for the feedback <3!'),
        messages: [t('Sorry! There seems to be an issue'), t('Please send your report to dev@halfnhalf.app')],
        variant: 'error',
      }, {
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },  
      });   
    }

    setOpen(false);
    setSubmitting(false)
  }


  return (
    <>
      <Tooltip arrow title={t('Something Broken?')}>
          <IconButtonWrapper
            color="warning"
            ref={ref}
            onClick={handleOpen}
            sx={{
              mx: 1,
              background: alpha(theme.colors.warning.main, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: theme.colors.warning.main,

              '&:hover': {
                background: alpha(theme.colors.warning.main, 0.2)
              }
            }}
          >
            <BrokenImageIcon fontSize="small" />
          </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={360} maxWidth={360} style={{ padding: 20}}>
          
          <Box>
            <FormControl variant="outlined" fullWidth>
              <InputLabel >{t('Subject Name ') + (email.subject ? `${email.subject.length} / 50`: ``)}</InputLabel>
              <OutlinedInputWrapper
                type="text"
                // eslint-disable-next-line prefer-template
                placeholder={t('Subject Name ') + (email.subject ? `${email.subject.length} / 50`: ``)}
                inputProps={{ maxLength: 50 }}
                value={email.subject}
                onChange={handleChange('subject')}
                startAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Divider />
        <FormControl fullWidth >
          <TextField
            inputProps={{ maxLength: 1000 }}
            id="standard-multiline-static" 
            // eslint-disable-next-line prefer-template
            label={t('Problem Details (please be specific) ') + (email.body ? `${email.body.length} / 1000`: ``)}
            multiline
            minRows={6} 
            maxRows={15} 
            value={email.body}
            onChange={handleChange('body')}
            variant="standard"
          />
          </FormControl>
          <Divider />
          <Box
            sx={{
              background: `${theme.colors.alpha.black[5]}`,
              textAlign: 'center'
            }}
            p={2}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={!email.body || !email.subject || email.body.length < 10 || email.subject.length < 5 || isSubmitting}
              onClick={submitReport}
              endIcon={<SendIcon />}
            >
              {t('Send Report')}
            </Button>
          </Box>
          <Text color="warning">
            <Box
              p={1.5}
              display="flex"
              alignItems="flex-start"
              sx={{
                maxWidth: 340
              }}
            >
              <FavoriteIcon fontSize="small" />
              <Typography
                variant="body1"
                sx={{
                  pl: 1,
                  fontSize: theme.typography.pxToRem(12)
                }}
              >
                {t(
                  'We appreciate all the feedback, your report will be used to make a better platform'
                )}
                !
              </Typography>
            </Box>
          </Text>
        </Box>
      </Popover>
    </>
  );
}

export default Chat;
