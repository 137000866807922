import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';
import { CircularProgress } from '@mui/material';

const Authenticated = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [pending, setPending] = useState(false);
  const loggedInUser = localStorage.getItem("hnh_user");

  if(loggedInUser && (!auth.isAuthenticated || auth.user.id === undefined || auth.user.id === null) && !pending){
    let userDetails = JSON.parse(window.atob(loggedInUser))
    auth.login(userDetails.email, userDetails.password, true)
    setPending(true)
    return <CircularProgress />;
  }
  
  if (!auth.isAuthenticated || auth.user.id === undefined || auth.user.id === null) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }
    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

Authenticated.propTypes = {
  children: PropTypes.node
};

export default Authenticated;
