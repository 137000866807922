import { createSlice } from '@reduxjs/toolkit';

// import _ from 'lodash';

import axios from 'axios';
 

const initialState = {
    dialogState: {
        isWorker: false,
        hasStore: false,
        attachWrker: true,
        rmWrker: false, 
        rmStore: false
    },
    operatingLocations: [],
    deals: [],
    region: null,
    retrieving: false,
    store: null,
    wrkerStores: [],
    wrkerDialog: false,
    filesToUpload: [], 
};

const slice = createSlice({
  name: 'store_platform',
  initialState,
  reducers: {
    setWrkerDialog(state, action){
        state.wrkerDialog = action.payload;
    },
    setFilesToUpload(state, action){
        state.filesToUpload = [...action.payload];
    },
    setDialog(state, action){
        let dialogState = action.payload;
        switch(dialogState){
    
          case 0: 
          state.dialogState = ({
            isWorker: false,
            hasStore: false,
            attachWrker: true,
            rmWrker: false, 
            rmStore: false
          });
          break;
    
          case 1: 
          state.dialogState = ({
            ...dialogState,
            attachWrker: false,
            rmWrker: true, 
            rmStore: false
          });
          break;
          
          case 2: 
          state.dialogState = ({
            ...dialogState,
            attachWrker: false,
            rmWrker: false, 
            rmStore: true
          });
          break;
    
          default: 
          state.dialogState = ({
            isWorker: false,
            hasStore: false,
            attachWrker: false,
            rmWrker: false, 
            rmStore: false
          });
          break;
        }
    },
    setRetrieving(state, action){
        state.retrieving = action.payload; 
    },
    setOperatingLocations(state, action){
        state.operatingLocations = action.payload;
    }, // TODO: move logic into main function not reudcer
    getDeals(state, action){
        state.deals = [...action.payload];  
    }, 
    checkWrkrStore(state, action){
        state.wrkerStores = [...action.payload]; 
    },
    setStore(state, action){
        state.store = action.payload;
    },
    setRegion(state, action){
        state.region = action.payload;
    }
  }
});

export const reducer = slice.reducer;
 
export const checkWrkrStore = (token, val) => async (dispatch) => { 
    dispatch(slice.actions.setRetrieving(true)) 
    if(val === null)
      return
    
    let config = {
      method: 'get',
      url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/store/location?filter=geo&latitude=${val.coords.lat}&longitude=${val.coords.lon}`,
      headers: { 
        'Accept': '*/*',
        'Authorization': `Bearer ${token}`
      }
    };
    console.log("***********---------------------********");
    console.log(config)

    
    let resp = await axios(config).catch(() => {
      console.error(`Can't login, error reaching backend.`);
      return {
        error: true
      }

    });  
    dispatch(slice.actions.setRegion(val))
    if(!resp.error && resp.data.code === 200 && resp.data && resp.data.data){
        dispatch(slice.actions.checkWrkrStore([...resp.data.data])) 
    }  
    dispatch(slice.actions.setRetrieving(false))
}
export const setStore = (val) => async (dispatch) => { 
    dispatch(slice.actions.setStore(val))
}
export const setWrkerDialog = (val) => async (dispatch) => { 
    dispatch(slice.actions.setWrkerDialog(val))
}
export const setFilesToUpload = (val) => async (dispatch) => { 
    dispatch(slice.actions.setFilesToUpload(val))
}
export const setDialog = (val) => async (dispatch) => { 
    dispatch(slice.actions.setDialog(val))
}

export const getLocations = ( ) => async (dispatch) => { 
    dispatch(slice.actions.setRetrieving(true))
    try {
      let response = null;
                        
      let config = {
        method: 'get',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/global/locations`,
        headers: { 
          'Accept': '*/*',
        }
      };

      
      response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
          error: true
        }

      }); 
      if(!response.error){  
        dispatch(slice.actions.setOperatingLocations(response.data.data.locations))
      }
      // setDeals([]);
    } catch (err) {
      console.error(err);
    }
    dispatch(slice.actions.setRetrieving(false))
  }


export const getDeals = (token, store) => async (dispatch) => { 
    dispatch(slice.actions.setRetrieving(true))

    
    try {
        let response = null;
        if(store !== null){
                          
          let config = {
            method: 'get',
            url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/store/deals`,
            headers: { 
              'Accept': '*/*',
              'Authorization': `Bearer ${token}`
            },
            params: {
              hashkey: store.hashKey,
              rangekey: store.rangeKey
            }
          };
        //   console.log("***********---------Deals------------********");
        //   console.log(store)
        //   console.log(config)
  
          
          response = await axios(config).catch(() => {
            console.error(`Can't login, error reaching backend.`);
            return {
              error: true
            }
  
          });
          
          if(!response.error){ 
            // console.log(response.data.data.deals);
            dispatch(slice.actions.getDeals([...response.data.data.deals])) 
          }
        } 
        // setDeals([]);
      } catch (err) {
        console.error(err);
      }
    dispatch(slice.actions.setRetrieving(false))
}

// eslint-disable-next-line no-unused-vars
export const uploadProof = (token, filesToUpload) => async (dispatch) => {   
    
    let config = {
        method: 'post',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/worker/app/images`,
        headers: { 
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        },
        data: { 
          imgList: [...filesToUpload],
        }
      };
      let response = await axios(config).catch((error) => {
        console.error(`Can't Fetch stores, error reaching backend.`, error);
        return {
          error: true
        }
  
      });
      console.log(response);
}

 
 

export default slice;
