import { useRoutes } from 'react-router-dom';
import router from 'src/router';  
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import usePageTracking from 'src/utils/analytics';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';

// ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  const content = useRoutes(router);
  const auth = useAuth();
  usePageTracking(); 
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}> 
        <CssBaseline />
        {auth.isInitialized ? content : <AppInit />}  
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
