import { ListSubheader, alpha, Box, List, styled } from '@mui/material'; 
// eslint-disable-next-line no-unused-vars
import { memo } from 'react'; 
import useAuth from 'src/hooks/useAuth';
import { useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarMenuItem from './item';
import menuItems from './items';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.Mui-active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  
  const { user } = useAuth(); 
  let items = []
  for(let x = 0; x < menuItems.length; x++){
    if(menuItems[x].heading === "Half N Half"){
      let menu = {
        heading: "Half N Half",
        items:[]
      };
      for(let i = 0; i < menuItems[x].items.length; i++){
        if(menuItems[x].items[i].name === 'Deals Platform' || menuItems[x].items[i].name === 'Messenger'){
          menu.items.push(menuItems[x].items[i]);
        }else {
          if(menuItems[x].items[i].name === 'Dispatch' && user.roles.includes('Admin')){
            menu.items.push(menuItems[x].items[i]);
          } 
          if(menuItems[x].items[i].name === 'Driver Dash' && user.roles.includes('Driver')){
            menu.items.push(menuItems[x].items[i]);
          }
          if((menuItems[x].items[i].name === 'Your Deal List' || menuItems[x].items[i].name === 'Create Deal')  && user.roles.includes('Worker')){
            menu.items.push(menuItems[x].items[i]);
          }
        }
      }
      items.push(menu);
    }else if(menuItems[x].heading === "Management"){
      if(user.roles.includes('Base'))
        items.push(menuItems[x]);
    }else { 
      items.push(menuItems[x]);
    }
  }



  // if(!user.roles.includes('Admin')) { 
  //   for(let x = 0; x < menuItems.length; x++){
  //     if(menuItems[x].heading === "Half N Half"){
  //       let menu = {
  //         heading: "Half N Half",
  //         items:[]
  //       };
  //       for(let i = 0; i < menuItems[x].items.length; i++){
  //         if(menuItems[x].items[i].name !== "Dispatch"){
  //           menu.items.push(menuItems[x].items[i]);
  //         }
  //       }
  //       items.push(menu);
  //     }else { 
  //       items.push(menuItems[x]);
  //     }
  //   }
  // }else if(!user.roles.includes('Worker')) { 
  //   for(let x = 0; x < menuItems.length; x++){
  //     if(menuItems[x].heading === "Half N Half"){
  //       let menu = {
  //         heading: "Half N Half",
  //         items:[]
  //       };
  //       for(let i = 0; i < menuItems[x].items.length; i++){
  //         if(menuItems[x].items[i].name !== "Dispatch" || menuItems[x].items[i].name !== "Create Deal" || menuItems[x].items[i].name !== "Your Deal List"){
  //           menu.items.push(menuItems[x].items[i]);
  //         }
  //       }
  //       items.push(menu);
  //     }else { 
  //       items.push(menuItems[x]);
  //     }
  //   }
  // }else {
  //   items = [...menuItems];
  // }
  console.log(items)
  // const Item = useMemo( props => {
  //   return (
  //     <MenuWrapper key={props.section.heading}>
  //       <List
  //         component="div"
  //         subheader={
  //           <ListSubheader component="div" disableSticky>
  //             {t(props.section.heading)}
  //           </ListSubheader>
  //         }
  //       >
  //         {renderSidebarMenuItems({
  //           items: props.section.items,
  //           path: location.pathname
  //         })}
  //       </List>
  //     </MenuWrapper>
  //   )
  // }, []);
  return (
    <>
      {items.map((section) => {


        return (
          <MenuWrapper key={section.heading}>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t(section.heading)}
                </ListSubheader>
              }
            >
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname
              })}
            </List>
          </MenuWrapper>
        )

      })}
    </>
  );
}

export default memo(SidebarMenu)
