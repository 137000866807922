import { useEffect, useRef, useReducer } from 'react';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';
import deepEqual from 'deep-equal'

/*
Cache
{
    get: {
        'http...': {
            reqParams: //defaults to null if no params
            reqHeaders: //defaults to null unless customs are set
            reqResponse:
        }
    }
}
const { status, data, error } = useFetch(true, true, url, 'get');
const articles = data.hits;

{status === 'idle' && (
					<div> Let's get started by searching for an article! </div>
				)}
				{status === 'error' && <div>{error}</div>}
				{status === 'fetching' && <div className="loading"></div>}
				{status === 'fetched' && (
					<>
						<div className="query"> Search results for {query} </div>
						{articles.length === 0 && <div> No articles found! :( </div>}
						{articles.map((article) => (
							<div className="article" key={article.objectID}>
								<a target="_blank" href={article.url} rel="noopener noreferrer">
									{article.title}
								</a>{' '}
								by {article.author}
							</div>
						))}
					</>
				)}
*/


const sendRequest = (isBeta, requiresAuth = false, url, reqType, reqData = null, cacheOptions = { shouldCache: false, overrideCache: false}, fullUrl = false, customHeaders = null) => {
	const cache = useRef({
		get: {},
		post: {},
		put: {},
		delete: {},
	});
    const { user } = useAuth();

	
	const axiosWithTimeout = async (config, timeout = 15000) => {
		if(cacheOptions.shouldCache && !cacheOptions.overrideCache){
			if(cache.current[reqType][url] && deepEqual(cache.current[reqType][url].reqParams , cache.current[reqType][url].reqParams )){
				return cache.current[reqType][url].reqResponse;
			}
		}


		return Promise.race([
			axios(config).catch((err) => { 
			return {...err}; 
		}),
		new Promise((_, reject) =>
			setTimeout(() => {
			// eslint-disable-next-line prefer-promise-reject-errors
			reject("(Timeout)");
			}, timeout)
		),
		]);
	};

	const initialState = {
		status: 'idle',
		error: null,
		data: [],
	};

	const [state, dispatch] = useReducer((state, action) => {
		switch (action.type) {
			case 'SENDING_REQUEST':
				return { ...initialState, status: 'sending' };
			case 'REQUESTED':
				return { ...initialState, status: 'sent', data: action.payload };
			case 'REQUEST_ERROR':
				return { ...initialState, status: 'error', error: action.payload };
			default:
				return state;
		}
	}, initialState);
    let baseUrl = [
        'https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/',
        'https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/',
    ]

	useEffect(() => {
		let cancelRequest = false;
		if (!url || !url.trim()) return;

		const sendRequest = async () => {
			console.log(url);
			dispatch({ type: 'SENDING_REQUEST' });
			try {
				let response = null;
				  
				let config = {
				  method: reqType,
				  headers: { 
					'Accept': '*/*'
				  }
				};
				if(customHeaders){
					config.headers = {...customHeaders};
				}

				if(fullUrl){
					config.url = url;
				}else {
					config.url = `${isBeta ? baseUrl[0] : baseUrl[1]}${url}`;
				}

				if(requiresAuth){
					config.headers.Authorization = `Bearer ${user.sessionToken}`;
				}

				if(reqType === 'get' && reqData){
					config.params = {...reqData}
				}else if(reqData){
					config.data = {...reqData}
				}
			
				
				response = await axiosWithTimeout(config)
				console.log(response)
				// const data = response.data;

				if(cacheOptions.shouldCache){
					cache.current[reqType][url] = {
						reqParams: (reqData) ? {...reqData} : null,
						reqHeaders: (customHeaders) ? {...customHeaders} : null,
						reqResponse: {...response}
					};
					/*
					{
						reqParams: (reqData) ? {...reqData} : null,
						reqHeaders: (customHeaders) ? {...customHeaders} : null,
						reqResponse: {...response}
					}
					*/
				}
				if (cancelRequest) return;
				dispatch({ type: 'REQUESTED', payload: response });
			} catch (error) {
				if (cancelRequest) return;
				dispatch({ type: 'REQUEST_ERROR', payload: error });
			}
		};

		sendRequest();

		// eslint-disable-next-line consistent-return
		return function cleanup() {
			cancelRequest = true;
		};
	}, [url, reqType]);

	return state;
};

export default sendRequest