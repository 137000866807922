import { createSlice } from '@reduxjs/toolkit';

// import _ from 'lodash';

import axios from 'axios';
 

const initialState = {
    isLoaded: false, 
    operatingLocations: [],
    selectedChat: null, 
    retrieving: false,
    retrievingChat: false,
    chats: {userChats: [], workerChats: []},
    mainChat: {
        curUserChat: [],
        otherUserChat: [],
        systemChat: [],
        state: null,
        order: null,
        transaction: null,
        deal: null,
        memberUser: null,
        memberWrkr: null
    },


    

};

const slice = createSlice({
  name: 'messenger_chats',
  initialState,
  reducers: {
    setOperatingLocations(state, action){
        const locations = action.payload;
        state.operatingLocations = [...locations]
    }, 
    setSelectedChat(state, action){ 
        state.selectedChat = action.payload;
    },
    setRetrieving(state, action){ 
        state.retrieving = action.payload;
    },
    setRetrievingChat(state, action){ 
        state.retrievingChat = action.payload;
    },
    setChats(state, action){
        const chats = action.payload; 
        state.chats = {...chats}
    },
    setMainChat(state, action){
        const mainChat = action.payload; 
        state.mainChat = {...mainChat}
    },
 
  }
});

export const reducer = slice.reducer;



export const setSelectedChat = (selectedChat)  => async (dispatch) =>  {
    dispatch(slice.actions.setSelectedChat(selectedChat));
}
export const getChatMsgs = (token, selectedChat)  => async (dispatch) =>  {
    // dispatch(slice.actions.setRetrievingChat(true));
    try {
      let response = null;
                        
      let config = {
        method: 'get',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/chat/message`,
        headers: { 
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        },
        params: {
          chatPK: selectedChat
        }
      };

      
      response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
          error: true
        }

      });
      if(!response.error){ 
        // console.log(response.data.data.chat)
        let data = {
          curUserChat: [...response.data.data.chat.requesterChat],
          otherUserChat: [...response.data.data.chat.otherChat],
          systemChat: [...response.data.data.chat.systemChat],
          state: response.data.data.chat.state,
          order: {...response.data.data.chat.order},
          transaction: {...response.data.data.chat.transaction},
          deal: {...response.data.data.chat.deal},
          memberUser: {...response.data.data.chat.memberUser},
          memberWrkr: {...response.data.data.chat.memberWrkr}
        }
        // console.log(data.memberWrkr, data.memberWrkr.SK, data.memberWrkr.SK.split("#")[3], user.worker.id, data.memberWrkr.SK.split("#")[3]===user.worker.id);
        // mainChat && mainChat.memberWrkr && mainChat.memberWrkr.SK && mainChat.memberWrkr.SK.split("#")[3] === user.worker.id
        
        // dispatch(slice.actions.setRetrievingChat(false));
        dispatch(slice.actions.setMainChat({...data}));
        
        // setChats({userChats: [...response.data.data.userChats], workerChats: [...response.data.data.workerChats]});
      }
    } catch (err) {
      console.error(err);
    }
  }
  
// eslint-disable-next-line no-unused-vars
export const sendChatMsg = (token, selectedChat, message, img = null)  => async (dispatch) =>  {
    try {
        let response = null;
        let imgList = [];
                        
        let config = {
        method: 'post',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/chat/message`,
        headers: { 
            'Accept': '*/*',
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify({
            msg: message,
            chat: {
            PK: selectedChat
            } 
        })
        };

        if(img){

        imgList.push(img);

        config = {
            method: 'post',
            url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/chat/images`,
            headers: { 
            'Accept': '*/*',
            'Authorization': `Bearer ${token}`
            },
            data: {
            chatpk: selectedChat,
            imgList: [...imgList],
            }
        };
        }
        
        response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
            error: true
        }

        });
        console.log(response);
        if(!response.error){ 
        await getChatMsgs(token); 
        // setChats({userChats: [...response.data.data.userChats], workerChats: [...response.data.data.workerChats]});
        }
    } catch (err) {
        console.error(err);
    }
}

export const getChats = (token)  => async (dispatch) =>  {
    try { 
        dispatch(slice.actions.setRetrieving(true));
      let response = null;
                        
      let config = {
        method: 'get',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/chat/general`,
        headers: { 
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        } 
      };

      
      response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
          error: true
        }

      });
      if(!response.error){ 
        let chatData = {userChats: [...response.data.data.userChats], workerChats: [...response.data.data.workerChats]}; 
        dispatch(slice.actions.setChats({...chatData}));
        dispatch(slice.actions.setRetrieving(false));
      }
    } catch (err) {
      console.error(err);
    }
}

export const getLocations = ()  => async (dispatch) =>  {
    try {
      let response = null;
                        
      let config = {
        method: 'get',
        url: `https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/global/locations`,
        headers: { 
          'Accept': '*/*',
        }
      };

      
      response = await axios(config).catch(() => {
        console.error(`Can't Fetch stores, error reaching backend.`);
        return {
          error: true
        }

      }); 
      if(!response.error){ 
        // setOperatingLocations(response.data.data.locations);
        dispatch(slice.actions.setOperatingLocations(response.data.data.locations));
      }
      // setDeals([]);
    } catch (err) {
      console.error(err);
    }
  }
 
export default slice;
