// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
// import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
// import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
// import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
// import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
// import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';


const menuItems = [
  {
    heading: 'Half N Half',
    items: [
      // {
      //   name: 'Dashboards',
      //   icon: SmartToyTwoToneIcon,
      //   link: '/site/dashboards',
      //   items: [ 
      //     {
      //       name: 'Deals Platform',
      //       link: 'dashboards/store-platform'
      //     },
      //     {
      //       name: 'Your Deal List',
      //       link: 'dashboards/commerce/deals/list'
      //     }, 
      //     {
      //       name: 'Create Deal',
      //       link: 'dashboards/commerce/deals/create'
      //     } 
      //   ]
      // }, 
      {
        name: 'Deals Platform',
        link: 'dashboards/store-platform'
      },
      {
        name: 'Your Deal List',
        link: 'dashboards/commerce/deals/list'
      }, 
      {
        name: 'Create Deal',
        link: 'dashboards/commerce/deals/create'
      },
      {
        name: 'Messenger',
        link: '/site/applications/messenger'
      },
      {
        name: 'Dispatch',
        link: 'dashboards/monitoring'
      },
      {
        name: 'Driver Dash',
        link: 'dashboards/driver'
      },
    ]
  },
  {
    heading: 'Management',
    items: [ 
      // {
      //   name: 'Finance',
      //   link: 'management/finance'
      // },
      // {
      //   name: 'Invoices',
      //   icon: ReceiptTwoToneIcon,
      //   link: '/site/management/invoices',
      //   items: [
      //     {
      //       name: 'List',
      //       link: 'management/invoices/list'
      //     },
      //     {
      //       name: 'Details',
      //       link: 'management/invoices/single'
      //     }
      //   ]
      // },
      {
        name: 'Orders',
        link: 'management/orders'
      }
    ]
  }, 
];

export default menuItems;
