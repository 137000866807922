import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards

// const Automation = Loader(
//   lazy(() => import('src/content/dashboards/Automation'))
// );
// const Analytics = Loader(
//   lazy(() => import('src/content/dashboards/Analytics'))
// );
// const Reports = Loader(lazy(() => import('src/content/dashboards/Reports')));
// const Banking = Loader(lazy(() => import('src/content/dashboards/Banking')));
const DealsPlatform = Loader(
  lazy(() => import('src/content/applications/StorePlatform'))
);
const Products = Loader(lazy(() => import('src/content/management/Deals')));
const CreateProduct = Loader(
  lazy(() => import('src/content/management/Deals/create'))
);
// const Fitness = Loader(lazy(() => import('src/content/dashboards/Fitness')));
// const HealthcareDoctor = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare'))
// );
// const HealthcareHospital = Loader(
//   lazy(() => import('src/content/dashboards/Healthcare/HospitalView'))
// );
// const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
// const Learning = Loader(lazy(() => import('src/content/dashboards/Learning')));
const Driving = Loader(
  lazy(() => import('src/content/dashboards/Driver'))
);
const DriverBatch = Loader(
  lazy(() => import('src/content/dashboards/DriverBatch'))
);
const Monitoring = Loader(
  lazy(() => import('src/content/dashboards/Monitoring'))
);
// const Products = Loader(lazy(() => import('src/content/dashboards/Deals')));
// const Statistics = Loader(
//   lazy(() => import('src/content/dashboards/Statistics'))
// );
// const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));

const dashboardsRoutes = [
  {
    path: '/',
    element: <Navigate to="store-platform" replace />
  },
  // {
  //   path: 'automation',
  //   element: <Automation />
  // },
  // {
  //   path: 'analytics',
  //   element: <Analytics />
  // },
  // {
  //   path: 'reports',
  //   element: <Reports />
  // },
  // {
  //   path: 'banking',
  //   element: <Banking />
  // },
  {
    path: 'store-platform',
    element: <DealsPlatform  />
  },
  {
    path: 'monitoring',
    element: <Monitoring />
  },
  {
    path: 'driver',
    children: [
      {
        path: '/',
        element: <Driving />
      },
      {
        path: 'batch',
        element: <DriverBatch />
      } 
    ] 
  },
  {
    path: 'commerce',
    children: [
      {
        path: '/',
        element: <Navigate to="deals" replace />
      },
      // {
      //   path: 'shop',
      //   element: <Shop />
      // },
      {
        path: 'deals',
        children: [
          {
            path: '/',
            element: <Navigate to="list" replace />
          },
          {
            path: 'list',
            element: <Products />
          },
          {
            path: 'create',
            element: <CreateProduct />
          },
          // {
          //   path: 'single',
          //   children: [
          //     {
          //       path: '/',
          //       element: <Navigate to="1" replace />
          //     },
          //     {
          //       path: ':productId',
          //       element: <SingleProduct />
          //     }
          //   ]
          // }
        ]
      }
    ]
  },
  // {
  //   path: 'expenses',
  //   element: <Expenses />
  // },
  // {
  //   path: 'crypto',
  //   element: <Crypto />
  // },
  // {
  //   path: 'fitness',
  //   element: <Fitness />
  // },
  // {
  //   path: 'healthcare',
  //   children: [
  //     {
  //       path: '/',
  //       element: <Navigate to="hospital" replace />
  //     },
  //     {
  //       path: 'hospital',
  //       element: <HealthcareHospital />
  //     },
  //     {
  //       path: 'doctor',
  //       element: <HealthcareDoctor />
  //     }
  //   ]
  // },
  // {
  //   path: 'helpdesk',
  //   element: <Helpdesk />
  // },
  // {
  //   path: 'learning',
  //   element: <Learning />
  // },
  // {
  //   path: 'monitoring',
  //   element: <Monitoring />
  // },
  // {
  //   path: 'products',
  //   element: <Products />
  // },
  // {
  //   path: 'statistics',
  //   element: <Statistics />
  // },
  // {
  //   path: 'tasks',
  //   element: <Tasks />
  // }
];

export default dashboardsRoutes;
