import { Stack } from '@mui/material';
// import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';
import Chat from './Chat';

function HeaderButtons() {
  return (
    <Stack direction='row'>
      <Chat />
      {/* <HeaderNotifications /> */}
      <LanguageSwitcher />
    </Stack>
  );
}

export default HeaderButtons;
