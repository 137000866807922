import React, { useState, useEffect, useRef } from 'react'; 
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
  CircularProgress,
  Grid
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'; 

import Text from 'src/components/Text';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';

import FinancialChart from 'src/content/dashboards/Finance/GeneralSummary'; 

import sendRequest from 'src/utils/tools/sendRequest'
// eslint-disable-next-line no-unused-vars 
const PreLoad = ({setData, user, outerRequest}) => { 
  const [requestDetails, setRequestDetails] = useState({...outerRequest})

  
  useEffect(() => {
    console.log("OUTTER REQ")
    console.log({...outerRequest})
    setRequestDetails({...outerRequest});
  }, [JSON.stringify(outerRequest)])
  let localData = sendRequest(true, true, requestDetails.url, requestDetails.type, requestDetails.params, requestDetails.cacheOptions.shouldCache); 
  useEffect(() => {
      if(localData.status === 'sent'){ 
        switch(requestDetails.url){
          case 'v1/user/financial' :
              // eslint-disable-next-line no-case-declarations
              // console.log(localData.data.data.data) 
              setData({...localData.data.data.data});
              
            break; 
          default: 
          break;
        } 
      }

  }, [localData.status]) 

  return (
    <>
      {localData.status === 'sending' && <CircularProgress size="5rem" /> }
    </>
  )
  
}
 

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
);

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
);

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

function HeaderUserbox() {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();

  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [requestHandler, setRequestHandler] = useState({
    url: 'v1/user/financial',
    params: null,
    cacheOptions: {shouldCache: false, overrideCache: false},
    type: 'get'
  });
  // eslint-disable-next-line no-unused-vars
  const [operationalData, setOperationalData] = useState({
    userChats: [],
    workerChats: []
  });
 

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={user.name} src={user.avatar} />
      </UserBoxButton>
      <Grid
      container
      fullWidth
      justifyContent="center"
      >
        <PreLoad setData={setOperationalData} user={user} outerRequest={requestHandler} /> 
      </Grid>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          <MenuItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('My account')}
            />
            <ChevronRightTwoToneIcon
              sx={{
                color: `${theme.colors.alpha.black[30]}`,
                opacity: 0.8
              }}
            />
          </MenuItem>
          <MenuItem>
            <ListItemText
              primaryTypographyProps={{
                variant: 'h5'
              }}
              primary={t('Profile settings')}
            />
            <Box display="flex" alignItems="center"> 
              <ChevronRightTwoToneIcon
                sx={{
                  ml: 1,
                  color: `${theme.colors.alpha.black[30]}`,
                  opacity: 0.8
                }}
              />
            </Box>
          </MenuItem> 
        </MenuListWrapperPrimary>
        <Divider />
        <Box m={1}>
          <Box px={2} pt={1} pb={0.5} display="flex" alignItems="center">
            <Text color="warning">
              <MonetizationOnTwoToneIcon fontSize="large" />
            </Text> 
          </Box>
 
        {operationalData.workerChats.length > 0 &&  
          <FinancialChart data={operationalData.workerChats} type="worker"/>  }
        {operationalData.userChats.length > 0 &&  
          <FinancialChart data={operationalData.userChats} type="user"/>  } 
        </Box>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
